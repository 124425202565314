<template>
  <div class="card p-4">
    <div class="is-title text-xl text-bold is-4 mb-4">Options</div>
    <div class="flex flex-row">
      <div class="w-3/4 mr-4">
        <b-field
          v-for="(option, index) in optionLabels"
          :key="option"
          :label="option"
        >
          <b-input
            :value="getOptionValue(index)"
            @input="updateOptions($event, index)"
          />
        </b-field>
      </div>
      <div class="w-1/4">
        <b-field>
          <item-image
            v-if="currentImage && !isUploading"
            :image-url="currentImage"
            :size="ImageSizeEnum.XXLarge"
          />
          <b-upload
            v-if="!currentImage || isUploading"
            v-model="file"
            drag-drop
            :loading="isUploading"
            @input="uploadVariantImage"
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large" />
                </p>
                <div>
                  <div class="text-xs text-gray-500">
                    or drag images to upload
                  </div>
                </div>
              </div>
            </section>
          </b-upload>
          <b-upload
            v-if="currentImage"
            v-model="file"
            :loading="isUploading"
            @input="uploadVariantImage"
          >
            <a class="has-text-centered text-xs">Change Image</a>
          </b-upload>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AssetPrefixKey,
  MAX_ALLOWED_FILE_SIZE_FOR_PRODUCT_IMAGE,
} from '../../constants/'
import Vue from 'vue'
import { uploadFileToS3 } from '../../lib/asset'
import ItemImage from '../shared/ItemImage.vue'
import { ImageSizeEnum } from '../../types'

export default Vue.extend({
  name: 'VariantList',
  components: {
    ItemImage,
  },
  props: {
    optionLabels: {
      type: Array,
      required: true,
      default: () => [],
    },
    option1: {
      type: String,
      default: '',
    },
    option2: {
      type: String,
      default: '',
    },
    option3: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: {},
      isUploading: false,
      currentImage: '',
      ImageSizeEnum,
    }
  },

  methods: {
    /**
     * Get the option value based on the index
     */
    getOptionValue(index) {
      return this['option' + (index + 1)]
    },

    /**
     * Emit the Updated Option value
     */
    updateOptions(event, index) {
      this.$emit('update-option' + (index + 1), event)
    },
    /**
     * Method to upload the variant image
     */
    async uploadVariantImage() {
      // Converting bytes to megabytes
      const fileSizeInMB = this.file.size / (1024 * 1024)
      if (fileSizeInMB <= MAX_ALLOWED_FILE_SIZE_FOR_PRODUCT_IMAGE) {
        try {
          this.isUploading = true
          this.currentImage = await uploadFileToS3(
            this.file,
            AssetPrefixKey.Inventory
          )
          this.$emit('update-image', this.currentImage)
          this.isUploading = false
        } catch (error) {
          this.$buefy.toast.open({
            message: 'Failed to upload the image. Please try again later.',
            type: 'is-danger',
            position: 'is-bottom',
          })
        }
        // Reset the file input
        this.file = {}
      } else {
        this.$buefy.toast.open({
          message: 'Image size should be less than 10MB.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
      this.isUploading = false
    },
  },
})
</script>
