<template>
  <div class="card">
    <div class="text-xl font-bold is-4 mx-4 my-4">Variants</div>

    <div class="card-content">
      <template v-for="(variant, index) in variants">
        <separator v-if="index !== 0" :key="variant.title" />
        <router-link
          :key="variant.title"
          class="text-current"
          :to="getVariantRoute(variant)"
        >
          <div :key="variant.title" class="flex flex-row items-center">
            <div class="border flex items-center p-1 mr-3 rounded">
              <item-image
                v-if="variant.imageUrl"
                :image-url="variant.imageUrl"
                :size="ImageSizeEnum.Large"
              />
              <b-icon
                v-else
                class="text-gray-600"
                icon="image"
                size="is-medium"
                custom-size="mdi-24px"
              />
            </div>
            <div>{{ variant.title }}</div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Separator from '../shared/Separator.vue'
import ItemImage from '../shared/ItemImage.vue'
import { ImageSizeEnum } from '../../types'

export default Vue.extend({
  name: 'VariantList',
  components: {
    Separator,
    ItemImage,
  },
  props: {
    variants: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      ImageSizeEnum,
    }
  },

  methods: {
    /**
     * Construct Variant Route URL
     */
    getVariantRoute(variant) {
      return `/products/${variant.productId}/variant/${variant.variantId}`
    },
  },
})
</script>
