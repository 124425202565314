












































































































































import Vue from 'vue'
import NavigateBack from '../../components/shared/NavigateBack.vue'
import VariantList from '../../components/products/VariantList.vue'
import VariantOptions from '../../components/products/VariantOptions.vue'
import Pricing from '../../components/products/Pricing.vue'
import Inventory from '../../components/products/Inventory.vue'
import Shipping from '../../components/products/Shipping.vue'
import Separator from '../../components/shared/Separator.vue'
import Loader from '../../components/shared/Loader.vue'
import ItemImage from '../../components/shared/ItemImage.vue'
import { ImageSizeEnum } from '../../types'
import {
  getProduct,
  getVariant,
  updateVariant,
  createVariant,
  deleteVariant,
} from '../../lib/product'
import {
  Price,
  Product,
  Variant,
  VariantInput,
  VariantTypeEnum,
  CurrencyEnum,
  Maybe,
  Tax,
  TaxCollectionEnum,
} from '../../../../shared/types/types'
import { generateVariantTitle } from '../../lib/utils'
import { validateVariant } from '../../lib/validation/product'
import Message from '../../components/shared/Message.vue'
import { errorMessage, SuccessMessage } from '@/constants/message'
import { MessageInfo, MessageTypeEnum } from '@/types'
import {
  createExcludeProductVariant,
  deleteExcludeProductVariant,
  getTaxes,
  getTaxesForProductVariant,
  updateTax,
} from '@/lib/tax'

export default Vue.extend({
  name: 'Variant',
  components: {
    NavigateBack,
    VariantList,
    VariantOptions,
    Pricing,
    Inventory,
    Shipping,
    Separator,
    Loader,
    Message,
    ItemImage,
  },
  props: {
    pageType: {
      type: String,
      default: 'NEW',
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      productId: '',
      variantId: '',
      product: {} as Maybe<Product>,
      variant: {} as Maybe<Variant>,
      variantInput: {} as VariantInput,
      ImageSizeEnum,
      message: {} as MessageInfo,
      deleteLoading: false,
      taxes: [] as Tax[],
      originalActiveTaxIds: [] as string[],
      activeTaxIds: [] as string[],
      excludedGlobalTaxes: [] as string[],
    }
  },

  computed: {
    /**
     * method to check whether prev variant exists
     */
    hasPreviousVariant(): boolean {
      return this.indexOfVariant(this.variant) === 0
    },
    /**
     * method to check whether next variant exists
     */
    hasNextVariant(): boolean {
      return (
        this.indexOfVariant(this.variant) + 1 === this.product?.variants.length
      )
    },

    getVariantLength(): number {
      return this.product?.variants ? this.product?.variants.length : 0
    },
    getVariantTitle(): Maybe<string> {
      return this.pageType == 'NEW'
        ? 'Add variant'
        : this.pageType == 'DUPLICATE'
        ? 'Duplicate a variant'
        : this.product?.title || ''
    },
    showDuplicateButton(): boolean {
      return this.pageType == 'EDIT' && !this.isLoading
    },
    showDuplicateInfo(): boolean {
      return this.pageType == 'DUPLICATE'
    },
  },

  async created() {
    try {
      this.productId = this.$route.params.productId
      this.variantId = this.$route.params.variantId
      this.isLoading = true
      this.product = await getProduct(this.productId)
      this.setTaxes()
      if (this.pageType == 'EDIT') {
        //
      } else if (this.pageType == 'ADD') {
        this.isLoading = false
      } else {
        this.isLoading = false
      }
      this.initialiseVariantInput()
    } catch (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: errorMessage.GENERIC_ERR_MSG,
        position: 'is-bottom',
        type: 'is-danger',
      })
    }
  },
  methods: {
    /**
     * Sets taxes, active tax ids, excluded global taxes
     */
    async setTaxes() {
      if (this.pageType == 'EDIT') {
        this.taxes = await getTaxesForProductVariant(
          this.productId,
          this.variantId
        )
        this.activeTaxIds = this.getActiveTaxIdsForVariant()
        this.excludedGlobalTaxes = this.taxes
          .filter(
            (tax) =>
              tax.collectionType === TaxCollectionEnum.AllProducts &&
              tax.excludedProductVariants.length &&
              tax.excludedProductVariants.find(
                (productVariant) =>
                  productVariant.productId === this.product?.productId &&
                  productVariant.variantId === this.variantId
              )
          )
          .map((tax) => tax.taxId)
      } else {
        this.taxes = await getTaxes(TaxCollectionEnum.AllProducts)
        this.activeTaxIds = [...this.taxes.map((tax) => tax.taxId)]
      }
    },
    /**
     * Method to show previous variant
     */
    showPreviousVariant() {
      const variantIndex = this.indexOfVariant(this.variant) - 1
      const variantId = this.product?.variants[variantIndex].variantId
      this.$router.push(`/products/${this.productId}/variant/${variantId}`)
    },
    /**
     * Method to show next variant
     */
    showNextVariant() {
      const variantIndex = this.indexOfVariant(this.variant) + 1
      const variantId = this.product?.variants[variantIndex].variantId
      this.$router.push(`/products/${this.productId}/variant/${variantId}`)
    },
    /**
     * Method to get the index of variant
     *
     * @param variant - variant to find the index of
     */
    indexOfVariant(variant: Maybe<Variant>): number {
      let variantIndex = -1
      this.product?.variants.forEach((item: Variant, index: number) => {
        if (variant?.variantId === item.variantId) {
          variantIndex = index
        }
      })
      return variantIndex
    },
    /**
     * Method to update the variant image
     *
     * @param imageUrl - url of the image
     */
    updateVariantImage(imageUrl: string) {
      this.variantInput.imageUrl = imageUrl
    },
    /**
     * get the variant details and Initialise variant input
     */
    async initialiseVariantInput() {
      if (this.$route.params.variantId) {
        try {
          this.variant = await getVariant(this.productId, this.variantId)
          this.variantInput = {
            title: this.variant?.title || '',
            option1: this.variant?.option1 || '',
            option2: this.variant?.option2 || undefined,
            option3: this.variant?.option3 || undefined,
            chargeTax: this.variant?.chargeTax || false,
            disableAutomaticDiscount:
              this.variant?.disableAutomaticDiscount || false,
            isOversellable: this.variant?.isOversellable || false,
            price: {
              amount: this.variant?.price?.amount || 0,
              currency: this.variant?.price?.currency || CurrencyEnum.Usd,
            },
            discountedPrice: {
              amount: this.variant?.discountedPrice?.amount || 0,
              currency:
                this.variant?.discountedPrice?.currency || CurrencyEnum.Usd,
            },
            internalPrice: {
              amount: this.variant?.internalPrice?.amount || 0,
              currency:
                this.variant?.internalPrice?.currency || CurrencyEnum.Usd,
            },
            upsellPrice: {
              amount: this.variant?.upsellPrice?.amount || 0,
              currency: this.variant?.upsellPrice?.currency || CurrencyEnum.Usd,
            },
            discountedUpsellPrice: {
              amount: this.variant?.discountedUpsellPrice?.amount || 0,
              currency:
                this.variant?.discountedUpsellPrice?.currency ||
                CurrencyEnum.Usd,
            },
            internalUpsellPrice: {
              amount: this.variant?.internalUpsellPrice?.amount || 0,
              currency:
                this.variant?.internalUpsellPrice?.currency || CurrencyEnum.Usd,
            },
            packagePrice: {
              amount: this.variant?.packagePrice?.amount || 0,
              currency:
                this.variant?.packagePrice?.currency || CurrencyEnum.Usd,
            },
            discountedPackagePrice: {
              amount: this.variant?.discountedPackagePrice?.amount || 0,
              currency:
                this.variant?.discountedPackagePrice?.currency ||
                CurrencyEnum.Usd,
            },
            internalPackagePrice: {
              amount: this.variant?.internalPackagePrice?.amount || 0,
              currency:
                this.variant?.internalPackagePrice?.currency ||
                CurrencyEnum.Usd,
            },
            trackQuantity: this.variant?.trackQuantity || false,
            quantity: this.variant?.quantity || 0,
            variantType: this.variant?.variantType || VariantTypeEnum.Physical,
            sku: this.variant?.sku || '',
            comparePrice: {
              amount: this.variant?.comparePrice?.amount || 0,
              currency:
                this.variant?.comparePrice?.currency || CurrencyEnum.Usd,
            },
            index: this.variant?.index || 0,
          }
          this.isLoading = false
        } catch (e) {
          this.$buefy.toast.open({
            message: errorMessage.GENERIC_ERR_MSG,
            position: 'is-bottom',
            type: 'is-danger',
          })
        }
      } else {
        this.variantInput = {
          title: '',
          option1: '',
          chargeTax: false,
          disableAutomaticDiscount: false,
          isOversellable: false,
          price: {
            amount: 0,
            currency: CurrencyEnum.Usd,
          },
          trackQuantity: false,
          quantity: 0,
          sku: null,
          imageUrl: '',
          variantType: VariantTypeEnum.Physical,
          index: this.product?.variants?.length || 0 - 1,
        }
      }
    },
    /**
     * Updates product variant's excluded global taxes
     *  */
    updateExcludedGlobalTaxes(variantId: string) {
      return Promise.all(
        this.taxes
          .filter((tax) => tax.collectionType === TaxCollectionEnum.AllProducts)
          .map((tax) => {
            if (
              !this.excludedGlobalTaxes.includes(tax.taxId) &&
              !this.activeTaxIds.includes(tax.taxId)
            ) {
              return createExcludeProductVariant({
                taxId: tax.taxId,
                productVariantForm: {
                  productId: this.productId,
                  variantId: variantId || '',
                },
              })
            } else if (
              this.excludedGlobalTaxes.includes(tax.taxId) &&
              this.activeTaxIds.includes(tax.taxId)
            ) {
              return deleteExcludeProductVariant({
                taxId: tax.taxId,
                productId: this.productId || '',
                variantId: variantId || '',
              })
            } else {
              return {}
            }
          })
      )
    },

    /**
     * Save (edit or create) variant
     */
    async saveVariant() {
      const optionLabels =
        this.product && this.product.optionLabels
          ? this.product?.optionLabels
          : []
      const validationObj = validateVariant(this.variantInput, optionLabels)
      try {
        if (validationObj.isValid) {
          this.isSaving = true
          this.message = {} as MessageInfo
          if (this.pageType === 'EDIT') {
            const response = await updateVariant(
              this.productId,
              this.variantId,
              this.variantInput
            )
            this.updateExcludedGlobalTaxes(this.variantId)
            if (response) {
              this.$buefy.toast.open({
                message: SuccessMessage.UPDATE_VARIANT_SUCCESS_MSG,
                position: 'is-bottom',
                type: 'is-success',
              })
            }
          } else {
            const response = await createVariant(
              this.productId,
              this.variantInput
            )
            this.updateExcludedGlobalTaxes(response?.variantId || '')
            this.$buefy.toast.open({
              message: SuccessMessage.CREATE_VARIANT_SUCCESS_MSG,
              position: 'is-bottom',
              type: 'is-success',
            })
            this.$router.push(
              `/products/${response?.productId}/variant/${response?.variantId}`
            )
          }

          await this.saveTaxes()

          this.isSaving = false
        } else {
          this.message = {
            type: MessageTypeEnum.Error,
            messageList: validationObj.messageList,
            message: `There is ${validationObj.messageList.length} error with this variant:`,
          }
        }
        this.isSaving = false
      } catch (e) {
        this.isSaving = false
        this.$buefy.toast.open({
          message: errorMessage.GENERIC_ERR_MSG,
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },

    /**
     * Method to delete the variant
     *
     * @param title - title of the variant to be deleted
     */
    async confirmVariantDelete(title: string) {
      this.$buefy.dialog.confirm({
        title: `Deleting ${title}?`,
        message: `Are you sure you want to delete the Variant <strong>${title}</strong>? This can't be undone.`,
        confirmText: 'Delete Variant',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isDeleting = true
          try {
            await deleteVariant(this.productId, this.variantId)
            this.$buefy.toast.open({
              message: SuccessMessage.DELETE_VARIANT_SUCCESS_MSG,
              position: 'is-bottom',
              type: 'is-success',
            })
            this.$router.push(`/products/${this.productId}`)
          } catch (e) {
            this.$buefy.toast.open({
              message: errorMessage.GENERIC_ERR_MSG,
              position: 'is-bottom',
              type: 'is-danger',
            })
          }
          this.isDeleting = false
        },
      })
    },

    /**
     * Handler to update price of the data
     * @param price of Price containing amount and currency
     * @param field which is the name of the pricing field
     */
    updatePrice(price: Price, field: string) {
      switch (field) {
        case 'price':
          this.variantInput.price = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'discountedPrice':
          this.variantInput.discountedPrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'internalPrice':
          this.variantInput.internalPrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'upsellPrice':
          this.variantInput.upsellPrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'discountedUpsellPrice':
          this.variantInput.discountedUpsellPrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'internalUpsellPrice':
          this.variantInput.internalUpsellPrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'packagePrice':
          this.variantInput.packagePrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'discountedPackagePrice':
          this.variantInput.discountedPackagePrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
        case 'internalPackagePrice':
          this.variantInput.internalPackagePrice = {
            amount: price?.amount || 0,
            currency: price?.currency || CurrencyEnum.Usd,
          }
          break
      }
    },
    /**
     * Handler to update compare price of the data
     */
    updateComparePrice(price: Price) {
      this.variantInput.comparePrice = {
        amount: price?.amount || 0,
        currency: price?.currency || CurrencyEnum.Usd,
      }
    },

    updateChargeTax(chargeTax: boolean) {
      this.variantInput.chargeTax = chargeTax
    },

    updateDisableAutomaticDiscount(disableAutomaticDiscount: boolean) {
      this.variantInput.disableAutomaticDiscount = disableAutomaticDiscount
    },
    /**
     * Handler to update sku
     */
    updateSku(sku: string) {
      this.variantInput.sku = sku
    },

    /**
     * Handler to update track quantity
     */
    updateTrackQuantity(trackQuantity: boolean) {
      this.variantInput.trackQuantity = trackQuantity
    },

    /**
     * Handler to update isOversellable
     */
    updateIsOversellable(isOversellable: boolean) {
      this.variantInput.isOversellable = isOversellable
    },

    /**
     * Handler to update quantity
     */
    updateQuantity(quantity: string) {
      this.variantInput.quantity = parseInt(quantity)
    },

    /**
     * Handler to update option values
     */
    updateOptions(event: string, option: string) {
      switch (option) {
        case 'option1':
          this.variantInput.option1 = event
          break
        case 'option2':
          this.variantInput.option2 = event
          break
        case 'option3':
          this.variantInput.option3 = event
          break
      }
      const { option1, option2, option3 } = this.variantInput
      this.variantInput.title = generateVariantTitle(
        option1,
        option2 || '',
        option3 || ''
      )
    },
    /**
     * Handler to update variant types
     *
     * @param variantType - a VariantTypeEnum <physical | virtual>
     */
    updateVariantType(variantType: VariantTypeEnum) {
      this.variantInput.variantType = variantType
    },
    /**
     * Get active tax ids for the product variant
     */
    getActiveTaxIdsForVariant() {
      return this.taxes
        .filter((tax) => this.isTaxActiveForVariant(tax, this.variantId))
        .map((tax) => tax.taxId)
    },
    /**
     * Check if a given tax is active for the variantId
     *
     * @param tax - tax object
     * @param variantId - variant id
     */
    isTaxActiveForVariant(tax: Tax, variantId: string): boolean {
      if (tax.collectionType === TaxCollectionEnum.AllProducts) {
        return !(
          tax.excludedProductVariants &&
          tax.excludedProductVariants.find(
            (excludedProductVariant) =>
              excludedProductVariant.productId === this.productId &&
              excludedProductVariant.variantId === variantId
          )
        )
      }

      if (tax.collectionType === TaxCollectionEnum.SelectedProductVariants) {
        const productVariant = tax.productVariants.find(
          (productVariant) =>
            productVariant.productId === this.productId &&
            productVariant.variantId === variantId
        )

        return productVariant?.isActive || false
      }

      return false
    },
    /**
     * Event handler to update activeTaxIds array
     *
     * @param activeTaxIds - updated array of active tax ids
     */
    updateActiveTaxIds(activeTaxIds: string[]) {
      this.activeTaxIds = activeTaxIds
    },
    /**
     * Get newly enabled & disbaled taxes for the product variant
     *
     * @param variantId - variant id
     */
    getModifiedTaxesForVariant(): Tax[][] {
      const originalActiveTaxIds = this.getActiveTaxIdsForVariant()

      const newlyEnabledTaxes = this.activeTaxIds
        .filter((taxId) => !originalActiveTaxIds.includes(taxId))
        .map(this.getTaxById)
        .filter(Boolean)

      const newlyDisabledTaxes = originalActiveTaxIds
        .filter((taxId) => !this.activeTaxIds.includes(taxId))
        .map(this.getTaxById)
        .filter(Boolean)

      return [newlyEnabledTaxes as Tax[], newlyDisabledTaxes as Tax[]]
    },
    /**
     * Get tax by id from this.allTaxes array
     *
     * @param taxId - tax id
     */
    getTaxById(taxId: string): Tax | undefined {
      return this.taxes.find((tax) => tax.taxId === taxId)
    },
    /**
     * Check if given tax is a global tax
     *
     * @param tax - tax object
     */
    isGlobalTax(tax: Tax): boolean {
      return tax.collectionType === TaxCollectionEnum.AllProducts
    },
    /**
     * Check if given tax is a collection tax
     *
     * @param tax - tax object
     */
    isCollectionTax(tax: Tax): boolean {
      return tax.collectionType === TaxCollectionEnum.SelectedProductVariants
    },
    async saveTaxes() {
      let taxPromises: Promise<any>[] = []

      const [enabledTaxes, disabledTaxes] = this.getModifiedTaxesForVariant()

      enabledTaxes.forEach((tax) => {
        if (this.isGlobalTax(tax)) {
          taxPromises.push(
            deleteExcludeProductVariant({
              taxId: tax.taxId,
              productId: this.productId,
              variantId: this.variantId,
            })
          )
        }

        if (this.isCollectionTax(tax)) {
          taxPromises.push(
            updateTax({
              taxId: tax.taxId,
              title: tax?.title,
              type: tax?.type,
              collectionType: tax?.collectionType,
              currency: CurrencyEnum.Usd,
              productVariants: [
                {
                  productId: this.productId,
                  variantId: this.variantId,
                  isActive: true,
                },
              ],
            })
          )
        }
      })

      disabledTaxes.forEach((tax) => {
        if (this.isGlobalTax(tax)) {
          taxPromises.push(
            createExcludeProductVariant({
              taxId: tax.taxId,
              productVariantForm: {
                productId: this.productId,
                variantId: this.variantId,
              },
            })
          )
        }

        if (this.isCollectionTax(tax)) {
          taxPromises.push(
            updateTax({
              taxId: tax.taxId,
              title: tax?.title,
              type: tax?.type,
              collectionType: tax?.collectionType,
              currency: CurrencyEnum.Usd,
              productVariants: [
                {
                  productId: this.productId,
                  variantId: this.variantId,
                  isActive: false,
                },
              ],
            })
          )
        }
      })

      await Promise.all(taxPromises)
    },
  },
})
